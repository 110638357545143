import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation to receive params
import './ParticipationPage.css';
import logo from '../logobk.png'; // Ensure this logo image exists in your project

function ParticipationPage() {
  const navigate = useNavigate();
  const location = useLocation();

  // Extract the parameters from the state passed via navigation
  const { voucherCode, selecteddigit, selectednumber } = location.state || {};

  const handleResultNavigation = () => {
    navigate('/result'); // Navigate to ResultPage
  };

  return (
    <div className="participation-container">
      <div className="logo-container">
        <img src={logo} alt="Buka77 Logo" className="logo" />
      </div>
      <h2 className="lottery-title">BUKA77 LOTTERY</h2>
      <p className="participated-message">YOU'RE PARTICIPATED!</p>
      
      {/* Display the received parameters */}
      <p className="digit-info">
        {selectednumber} - {selecteddigit}D
      </p>
      <div className="voucher-info">
        <p>Voucher Code: {voucherCode}</p>
      </div>

      <div className="opening-time">
        <p>Opening Soon</p>
        <h1>18:00 (UTC+7)</h1>
        {/* Link that triggers the navigation to ResultPage */}
        <a className="refresh-link" onClick={handleResultNavigation}>
          Tonton Live Result
        </a>
      </div>
    </div>
  );
}

export default ParticipationPage;
