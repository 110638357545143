import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref, onValue, update } from 'firebase/database';
import { database } from '../context/firebase';
import './VoucherPage.css';
import logo from '../logobk.png'; // Ensure this logo image exists in your project

function VoucherPage() {
  const navigate = useNavigate();
  const [voucherCode, setVoucherCode] = useState('');

  const handleRedeem = () => {
    const dataVoucherRef = ref(database, 'dataVoucher');

    onValue(dataVoucherRef, (snapshot) => {
      const data = snapshot.val();
      let voucherFound = false;

      if (data) {
        // Check if the entered voucherCode exists in dataVoucher
        for (let key in data) {
          if (data[key].voucherCode === voucherCode) {
            voucherFound = true;

            if (data[key].status === "available") {
              // Update the status to "used"
              const voucherRef = ref(database, `dataVoucher/${key}`);
              update(voucherRef, { status: "used" })
                .then(() => {
                  // Navigate to /input-data with voucherCode as parameter
                  navigate('/input-data', { state: { voucherCode: voucherCode } });
                })
                .catch((error) => {
                  console.error("Error updating voucher status:", error);
                  alert("Failed to update voucher status. Please try again.");
                });
            } else {
              // Show alert if voucher is already used
              alert('Voucher tidak valid atau sudah digunakan');
            }
            break;
          }
        }
      }

      // Show alert if voucherCode not found
      if (!voucherFound) {
        alert('Voucher tidak valid atau sudah digunakan');
      }
    }, { onlyOnce: true }); // Ensure it reads only once to prevent multiple reads
  };

  return (
    <div className="voucher-page-container">
      <div className="logo-container">
        <img src={logo} alt="Buka77 Logo" style={{ width: 100 }} />
      </div>
      <h2 className="lottery-title">BUKA77 LOTTERY</h2>
      <p className="subtitle">Input Voucher Code</p>
      <div className="input-container">
        <input
          type="text"
          placeholder="Enter your voucher code"
          className="input-field"
          value={voucherCode}
          onChange={(e) => setVoucherCode(e.target.value)}
        />
      </div>
      <button className="redeem-button" onClick={handleRedeem}>
        Redeem
      </button>
    </div>
  );
}

export default VoucherPage;
