import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage';
import VoucherPage from './pages/VoucherPage';
import InputDataPage from './pages/InputDataPage';
import ParticipationPage from './pages/ParticipationPage';
import ResultPage from './pages/ResultPage';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/voucher" element={<VoucherPage />} />
          <Route path="/input-data" element={<InputDataPage />} />
          <Route path="/participation" element={<ParticipationPage />} />
          <Route path="/result" element={<ResultPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
