import React from 'react';
import './ResultPage.css';
import logo from '../logobk.png';

function ResultPage() {
  return (
    <div className="result-container">
      <div className="content-box">
        <div className="header">
          <img src={logo} alt="Buka77 Logo" className="logo" />
          <h2 className="title">BUKA77 LOTTERY</h2>
     
        </div>
        <div className="video-box">
          <iframe 
            width="100%" 
            height="200" 
            src="https://www.youtube.com/embed/vHYG-BM9pTU" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen
          ></iframe>
        </div>
        <button className="main-button">Help Center</button>
      </div>
    </div>
  );
}

export default ResultPage;
