import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';
import logo from '../logobk.png'; // Make sure you have this logo image in your project

function HomePage() {
  const navigate = useNavigate();

  const handleStart = () => {
    navigate('/voucher'); // This will navigate to the VoucherPage
  };

  return (
    <div className="homepage-container">
      <div className="logo-container">
        <img src={logo} alt="Buka77 Logo" style={{ width: 150, height: 150 }} />
      </div>
      <h1 className="welcome-text">WELCOME TO</h1>
      <h2 className="lottery-title">BUKA77 LOTTERY</h2>
      <div className="buttons-container">
        <button className="main-button" onClick={handleStart}>Mulai Main</button>
        <button className="main-button">Cara Ikut</button>
      </div>
    </div>
  );
}

export default HomePage;
