import React, { useState } from 'react';
import './InputDataPage.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { ref, push, onValue } from 'firebase/database';
import { database } from '../context/firebase';

function InputDataPage() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedDigit, setSelectedDigit] = useState('2'); // Default to "2D"
  const [inputNumber, setInputNumber] = useState('');
  const [userId, setUserId] = useState('');

  const [digitLimit, setDigitLimit] = useState(2); // Default to 2 digits for "2D"
  
  const navigate = useNavigate();
  const location = useLocation();
  const voucherCode = location.state?.voucherCode || '';

  const handleDigitChange = (event) => {
    const selectedValue = event.target.value;
    const maxDigits = parseInt(selectedValue);
    setSelectedDigit(selectedValue + 'D');
    setDigitLimit(maxDigits);
  };

  const handlePlay = () => {
    if (!phoneNumber || !inputNumber) {
      alert("Please fill in all fields");
      return;
    }

    const currentDate = new Date().toLocaleDateString('en-GB'); // Format as "dd-mm-yyyy"
    const participantsRef = ref(database, 'dataParticipants');

    // Fetch data from Firebase and check for duplicates
    onValue(participantsRef, (snapshot) => {
      const data = snapshot.val();
      let isDuplicate = false;

      if (data) {
        for (let key in data) {
          const participant = data[key];
          if (
            participant.date === currentDate &&
            participant.selecteddigit === selectedDigit &&
            participant.selectednumber === inputNumber
          ) {
            isDuplicate = true;
            break;
          }
        }
      }

      if (isDuplicate) {
        alert("The selected number already exists for today with the chosen digit type. Please choose a different number.");
      } else {
        // If no duplicate is found, proceed with saving the data
        const participantData = {
          date: currentDate,
          phonenumber: phoneNumber,
          selecteddigit: selectedDigit,
          selectednumber: inputNumber,
          voucherCode: voucherCode,
          userId: userId
        };

        push(participantsRef, participantData)
          .then(() => {
            alert("Data saved successfully!");
            navigate('/participation', {
              state: {
                voucherCode: voucherCode,
                selecteddigit: selectedDigit,
                selectednumber: inputNumber
              }
            });
          })
          .catch((error) => {
            console.error("Error saving data:", error);
            alert("Failed to save data. Please try again.");
          });
      }
    }, { onlyOnce: true });
  };

  return (
    <div className="input-data-container">
      <h2 style={{ color: 'black', marginBottom: 20 }}>Input Your Data</h2>
      
      <div className="input-box">
       
          <input
          type="text"
          placeholder="User ID"
          className="input-field"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
        />
      </div>
      
      <input
          type="text"
          placeholder="Nomor Whatsapp"
          className="input-field"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />


      <div className="input-box">
        <select className="input-field" onChange={handleDigitChange}>
          <option value="2">2D</option>
          <option value="3">3D</option>
          <option value="4">4D</option>
          <option value="5">5D</option>
          <option value="6">6D</option>
        </select>
      </div>
      
      <div className="input-box">
        <input
          type="text"
          placeholder="Input Your Number"
          className="input-field"
          maxLength={digitLimit}
          value={inputNumber}
          onChange={(e) => setInputNumber(e.target.value.replace(/[^0-9]/g, ''))} // Allow only numbers
        />
      </div>
      
      <button className="main-button" onClick={handlePlay}>LET'S PLAY!</button>
    </div>
  );
}

export default InputDataPage;
